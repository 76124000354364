import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const FlipCard = () => {
  const steps = [
    "Connect with friends and grow your network effortlessly.",
    "Showcase your personality with a customizable profile.",
    "Easily share your social links with a single, unique URL.",
    "Discover new people and communities with similar interests.",
    "Access your links and updates from anywhere, anytime.",
    "Streamline your online presence with one central hub.",
    "Enhance your personal brand with a professional-looking profile.",
    "Gain insights into who visits your profile and engages with your links.",
    "Stay secure with privacy settings to control your visibility.",
    "Join a fast-growing community focused on meaningful connections.",
  ];

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStepIndex((prevIndex) => (prevIndex + 1) % steps.length);
    }, 3000); // Adjust interval as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        perspective: "2000px",
        width: "500px", // Increased width
        height: "300px", // Increased height
        position: "relative",
      }}
    >
      <AnimatePresence mode="wait">
        <motion.div
          key={currentStepIndex}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#5d001e",
            color: "#fff",
            fontSize: "1.5rem", // Increased font size
            fontWeight: "bold",
            textAlign: "center",
            padding: "40px", // Added padding for longer text// Optional for rounded corners
          }}
          initial={{ rotateY: 90, opacity: 0 }}
          animate={{ rotateY: 0, opacity: 1 }}
          exit={{ rotateY: -90, opacity: 0 }}
          transition={{ duration: 0.6 }}
        >
          {steps[currentStepIndex]}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default FlipCard;
