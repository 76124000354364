import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  AlertColor,
} from "@mui/material";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { auth, db } from "../../firebase";

interface Props {
  activeFields: string[];
  setActiveFields: (fields: string[]) => void;
  links: { [key: string]: string };
  setLinks: (links: { [key: string]: string }) => void;
  handleSaveLinks: () => void;
  setSnackbar: (snackbar: {
    open: boolean;
    message: string;
    type: AlertColor;
  }) => void;
}

export default function AddSocialLinks({
  activeFields,
  setActiveFields,
  links,
  setLinks,
  handleSaveLinks,
  setSnackbar,
}: Props) {
  const platforms = [
    {
      name: "Facebook",
      icon: <FaFacebook className="text-blue-600 text-2xl" />,
    },
    {
      name: "Instagram",
      icon: <FaInstagram className="text-pink-500 text-2xl" />,
    },
    { name: "Twitter", icon: <FaTwitter className="text-blue-400 text-2xl" /> },
    {
      name: "LinkedIn",
      icon: <FaLinkedin className="text-blue-700 text-2xl" />,
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPlatform, setCurrentPlatform] = useState("");
  const [customPlatform, setCustomPlatform] = useState("");
  const [customUrl, setCustomUrl] = useState("");

  const handleAddField = (platform: string) => {
    setCurrentPlatform(platform.toLowerCase());
    setIsModalOpen(true);
  };

  const handleSaveCustomField = async () => {
    if (customPlatform.trim() && customUrl.trim()) {
      const platformKey = customPlatform.toLowerCase();

      try {
        const user = auth.currentUser;
        if (!user) {
          setSnackbar({
            open: true,
            message: "Please log in to save your links.",
            type: "error",
          });
          return;
        }

        // Add custom link to Firebase
        const userDocRef = doc(db, "users", user.uid);
        const newLink = {
          id: platformKey,
          title: customPlatform,
          url: customUrl,
        };

        await updateDoc(userDocRef, { links: arrayUnion(newLink) });

        // Update local state
        setLinks({ ...links, [platformKey]: customUrl });
        setActiveFields([...activeFields, platformKey]);

        // Reset modal state
        setCustomPlatform("");
        setCustomUrl("");
        setIsModalOpen(false);

        // Show success Snackbar
        setSnackbar({
          open: true,
          message: "Custom link added successfully!",
          type: "success",
        });
      } catch (error) {
        console.error("Error saving custom link:", error);
        setSnackbar({
          open: true,
          message: "Failed to save custom link.",
          type: "error",
        });
      }
    } else {
      setSnackbar({
        open: true,
        message: "Please fill out both fields.",
        type: "error",
      });
    }
  };

  return (
    <div className="relative mb-6">
      <Button
        variant="contained"
        fullWidth
        onClick={() => setIsModalOpen(true)}
        sx={{
          backgroundColor: "#1E2761",
          color: "#fff",
          fontSize: "1.1rem",
          fontWeight: "bold",
          textTransform: "none",
          padding: "10px",
          borderRadius: "0",
          "&:hover": { backgroundColor: "#16204D" },
        }}
      >
        Add Link
      </Button>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            width: "90%",
            maxWidth: 500,
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            gutterBottom
            sx={{ color: "#000000", textAlign: "center" }}
          >
            Add your link
          </Typography>
          <Divider sx={{ my: 2 }} />

          {currentPlatform ? (
            <>
              <Typography
                variant="body1"
                sx={{ fontWeight: "medium", color: "#408EC6", mb: 1 }}
              >
                Enter{" "}
                {currentPlatform.charAt(0).toUpperCase() +
                  currentPlatform.slice(1)}{" "}
                URL:
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                label={`${currentPlatform} URL`}
                value={links[currentPlatform] || ""}
                onChange={(e) =>
                  setLinks({ ...links, [currentPlatform]: e.target.value })
                }
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": { borderColor: "#408EC6" },
                  },
                }}
              />
              <Box display="flex" justifyContent="space-between">
                <Button
                  variant="outlined"
                  onClick={() => setIsModalOpen(false)}
                  sx={{
                    textTransform: "none",
                    borderColor: "#7A2048",
                    color: "#7A2048",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#F7E5EB",
                      borderColor: "#601732",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSaveCustomField}
                  sx={{
                    backgroundColor: "#7A2048",
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "#601732" },
                  }}
                >
                  Save Link
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Typography
                variant="body1"
                sx={{ fontWeight: "medium", color: "#408EC6", mb: 1 }}
              >
                Select from Popular Platforms:
              </Typography>
              <List>
                {platforms.map((platform) => (
                  <ListItem disablePadding key={platform.name}>
                    <ListItemButton
                      onClick={() => handleAddField(platform.name)}
                      sx={{
                        "&:hover": { backgroundColor: "#F7F7F7" },
                        borderRadius: "4px",
                        padding: "10px",
                        border: "1px solid #E0E0E0",
                        mb: 1,
                      }}
                    >
                      <div className="flex items-center space-x-3">
                        {platform.icon}
                        <ListItemText
                          primary={platform.name}
                          primaryTypographyProps={{
                            fontSize: "1rem",
                            fontWeight: "medium",
                            color: "#1E2761",
                          }}
                        />
                      </div>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>

              <Divider sx={{ my: 2 }} />

              <Typography
                variant="body1"
                sx={{ fontWeight: "medium", color: "#408EC6", mb: 1 }}
              >
                Add a Custom Platform:
              </Typography>
              <TextField
                label="Platform Name"
                variant="outlined"
                fullWidth
                margin="dense"
                value={customPlatform}
                onChange={(e) => setCustomPlatform(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    "&.Mui-focused fieldset": { borderColor: "#408EC6" },
                  },
                }}
              />
              <TextField
                label="Platform URL"
                variant="outlined"
                fullWidth
                margin="dense"
                value={customUrl}
                onChange={(e) => setCustomUrl(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    "&.Mui-focused fieldset": { borderColor: "#408EC6" },
                  },
                }}
              />
              <Box display="flex" justifyContent="space-between" mt={3}>
                <Button
                  variant="outlined"
                  onClick={() => setIsModalOpen(false)}
                  sx={{
                    textTransform: "none",
                    borderColor: "#7A2048",
                    color: "#7A2048",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#F7E5EB",
                      borderColor: "#601732",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSaveCustomField}
                  sx={{
                    backgroundColor: "#7A2048",
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "#601732" },
                  }}
                >
                  Add Custom Link
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
