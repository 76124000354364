import React from "react";
import Navbar from "./Navbar/Navbar";
const Pricing = () => {
  return (
    <>
      <Navbar />
      <div
        className="flex items-center justify-center min-h-screen text-white"
        style={{
          backgroundImage: "linear-gradient(to right, #6a11cb, #2575fc)",
        }}
      >
        <h1 className="text-6xl md:text-8xl font-extrabold text-center animate-pulse">
          COMING SOON
        </h1>
      </div>
    </>
  );
};

export default Pricing;
