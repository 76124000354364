import React, { useEffect, useRef } from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer";
import Typed from "typed.js";
import {
  FaInstagram,
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
  FaWhatsapp,
  FaSnapchat,
  FaTiktok,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import FlipCard from "./FlipCard";
import SlidingLinks from "./SlidingLinks";
import AnimatedPlanesTrains from "./AnimatedPlaneTrains";

const HomePage = () => {
  const typingRef = useRef(null);

  useEffect(() => {
    const typed = new Typed(typingRef.current, {
      strings: ["byrah.com/u/USERNAME", "Best link in bio tool"],
      typeSpeed: 30,
      backSpeed: 50,
      startDelay: 500,
      backDelay: 2000,
      loop: true,
      showCursor: false, // Remove the blinking cursor
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gray-50 text-gray-800 overflow-x-hidden">
      <Navbar />

      {/* First Section */}
      <div className="flex-1 h-screen bg-[#ccff00] text-[#000000] flex flex-col justify-center items-center px-4 py-16 md:p-24">
        <h1 className="text-5xl md:text-8xl font-extrabold mb-6 text-center animate-fade-in">
          Your link in bio tool
        </h1>

        <div
          className="text-lg md:text-2xl text-center max-w-screen-sm mb-6"
          style={{ minHeight: "2em" }}
        >
          <span ref={typingRef}></span>
        </div>
        <Link
          to="/signup"
          className="px-8 py-4 bg-black text-white hover:from-cyan-400 hover:to-blue-400 transition"
        >
          Get Started for Free
        </Link>
      </div>

      {/* Second Section */}
      <div className="flex-1 h-screen bg-red-700 text-[#ffffff] flex flex-col md:flex-row items-center justify-center py-12 px-4 md:py-24 md:px-16">
        <div className="max-w-screen-sm text-center md:text-left mb-12 md:mb-0 md:pr-8 md:mr-40">
          <h2 className="text-3xl md:text-5xl font-bold mb-6">
            Why Choose Byrah?
          </h2>
          <p className="text-lg md:text-2xl leading-7">
            Byrah is your gateway to managing and sharing your online identity.
            Join thousands of users who trust us to deliver fast, beautiful, and
            insightful link-sharing pages.
          </p>
        </div>
        <div className="w-full max-w-xs md:max-w-md flex justify-center items-center shadow-lg bg-[#ff4d4d] aspect-square p-6 ">
          <FlipCard />
        </div>
      </div>

      <div className="flex h-auto bg-[#cbbd93] items-center px-4 py-10 flex-col">
        <h2 className="text-4xl font-bold text-[#000000] mb-8">
          3 Easy Steps to Set Up
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-screen-2xl">
          <div className="bg-[#332216] py-10 px-8 text-center shadow-2xl transform transition-transform duration-300 hover:scale-105">
            <h3 className="text-3xl font-bold text-white mb-4">Step 1</h3>
            <p className="text-2xl text-gray-300">
              Sign up with email, username, and password
            </p>
          </div>
          <div className="bg-[#332216] py-10 px-8 text-center shadow-2xl transform transition-transform duration-300 hover:scale-105">
            <h3 className="text-3xl font-bold text-white mb-4">Step 2</h3>
            <p className="text-2xl text-gray-300">Customize your profile</p>
          </div>
          <div className="bg-[#332216] py-10 px-8 text-center shadow-2xl transform transition-transform duration-300 hover:scale-105">
            <h3 className="text-3xl font-bold text-white mb-4">Step 3</h3>
            <p className="text-2xl text-gray-300">
              Share your profile with a link
            </p>
          </div>
        </div>
      </div>

      {/* Third Section */}
      <div className="flex-1 h-screen bg-[#160C28] text-white flex flex-col items-center justify-center py-12 px-4 md:py-24 md:px-16">
        <div className="text-center mb-20">
          <h2 className="text-4xl md:text-6xl font-extrabold mb-6">
            Links We Support
          </h2>
          <p className="text-lg md:text-2xl leading-7 max-w-2xl mx-auto mb-10">
            Connect all your favorite platforms in one place. Build your unified
            digital presence effortlessly.
          </p>
        </div>

        <SlidingLinks />
      </div>

      {/* Insights Section */}
      <div className="flex-1 bg-[#9681AC] py-12 px-4 md:py-24 md:px-16 w-full text-white">
        <div className="flex flex-col items-center text-center">
          <h2 className="text-3xl md:text-5xl font-bold mb-6">
            Gain Insights with Google Analytics
          </h2>
          <p className="text-lg md:text-2xl leading-7 max-w-screen-sm mb-8">
            Track your page performance with Google Analytics. See how many
            users visited your page, monitor sign-ups, and understand your
            audience better with our integrated analytics tools.
          </p>
          <div className="shadow-lg w-full max-w-lg h-72 flex items-center justify-center bg-gray-200 text-gray-700 text-4xl font-bold">
            Coming Soon
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
