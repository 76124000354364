import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";

interface Props {
  backgroundColor: string;
  setBackgroundColor: (color: string) => void;
}

export default function BackgroundColorSelector({
  backgroundColor,
  setBackgroundColor,
}: Props) {
  // Extended modern color palette
  const colors = [
    "#FFE0E0",
    "#FFE0ED",
    "#F5E0FF",
    "#E0E0FF",
    "#E0EDFF",
    "#E0FFF4",
    "#E0FFE0",
    "#FFFFE0",
    "#FFB3B3",
    "#FFB3D1",
    "#E4B3FF",
    "#B3B3FF",
    "#B3D1FF",
    "#B3FFE4",
    "#B3FFB3",
    "#FFFFB3",
    "#FF6666",
    "#FF66AA",
    "#CC66FF",
    "#6666FF",
    "#66AAFF",
    "#66FFCC",
    "#66FF66",
    "#FFFF66",
    "#FF0000",
    "#FF0066",
    "#9933FF",
    "#0000FF",
    "#0066FF",
    "#00FF99",
    "#00FF00",
    "#FFFF00",
    "#CC0000",
    "#CC0052",
    "#7F2ACC",
    "#0000CC",
    "#0052CC",
    "#00CC7A",
    "#00CC00",
    "#CCCC00",
  ];

  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(
    null
  );

  const handleConfirmColor = () => {
    setConfirmationMessage(`Color changed to ${backgroundColor}`);
    setTimeout(() => setConfirmationMessage(null), 3000); // Remove message after 3 seconds
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "0 auto" }}>
      <Typography
        variant="h6"
        sx={{
          mb: 2,
          fontWeight: 600,
          letterSpacing: "-0.02em",
        }}
      >
        Select a Color Theme
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(8, 1fr)",
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
        }}
      >
        {colors.map((color) => (
          <Box
            key={color}
            onClick={() => setBackgroundColor(color)}
            sx={{
              position: "relative",
              cursor: "pointer",
              paddingTop: "100%", // Makes boxes square
              backgroundColor: color,
              transition: "all 0.2s ease",
              "&:hover": {
                transform: "scale(1.1)",
                zIndex: 1,
                boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
              },
              "&::after":
                backgroundColor === color
                  ? {
                      content: '""',
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "30%",
                      height: "30%",
                      borderRadius: "50%",
                      border: "2px solid white",
                      boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                    }
                  : {},
            }}
          />
        ))}
      </Box>

      <Box sx={{ mt: 2, textAlign: "center" }}>
        {confirmationMessage && (
          <Typography
            variant="body1"
            sx={{ mt: 2, color: "green", fontWeight: 500 }}
          >
            {confirmationMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
