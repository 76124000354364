import React, { useState } from "react";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar/Navbar";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/profile");
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  return (
    <>
      <Navbar />
      <div
        className="flex items-center justify-center min-h-screen"
        style={{
          backgroundColor: "#0049B7",
        }}
      >
        <div className="bg-white p-10 shadow-2xl max-w-lg w-full border-t-8 border-[#000000] mx-4 sm:mx-8 md:mx-16 lg:mx-0">
          <h2 className="text-4xl font-extrabold text-center mb-6 text-[#1C3334]">
            Welcome back!
          </h2>
          <p className="text-center text-[#000000] mb-6">
            Please log in to access your account
          </p>
          <form onSubmit={handleLogin} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-[#000000] mb-1">
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="w-full px-4 py-3 text-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 transition shadow-sm bg-gray-100"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-[#000000] mb-1">
                Password
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="w-full px-4 py-3 text-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 transition shadow-sm bg-gray-100"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-[#000000] text-white text-lg font-bold hover:bg-[#552619] transition duration-300 transform hover:scale-105 shadow-lg"
            >
              Log In
            </button>
          </form>
          <p className="text-center text-[#000000] mt-6">
            Don't have an account?{" "}
            <span
              className="text-[#02A1C0] underline cursor-pointer hover:text-[#552619]"
              onClick={() => navigate("/signup")}
            >
              Sign up
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
