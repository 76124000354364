import React from "react";
import { Link } from "react-router-dom";

// Define props for MobileNavbar
type NavbarProps = {
  isAuthenticated: boolean;
  handleSignOut: () => void;
};

type MobileNavbarProps = NavbarProps & {
  toggleMenu: () => void;
  isOpen: boolean;
};

const MobileNavbar: React.FC<MobileNavbarProps> = ({
  isAuthenticated,
  toggleMenu,
  handleSignOut,
  isOpen,
}) => {
  return (
    <>
      <div className="md:hidden">
        <button
          onClick={toggleMenu}
          className="text-white hover:text-indigo-600 focus:outline-none"
        >
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {isOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            )}
          </svg>
        </button>
      </div>
      {isOpen && (
        <div className="md:hidden bg-black border-t border-gray-200 shadow-md z-50">
          <div className="flex flex-col px-4 py-4 space-y-3 h-auto mt-40">
            <Link
              to="/home"
              className="text-lg text-white hover:text-indigo-600 font-medium transition"
              onClick={toggleMenu}
            >
              Home
            </Link>
            <Link
              to="/about"
              className="text-lg text-white hover:text-indigo-600 font-medium transition"
              onClick={toggleMenu}
            >
              About
            </Link>
            <Link
              to="/features"
              className="text-lg text-white hover:text-indigo-600 font-medium transition"
              onClick={toggleMenu}
            >
              Features
            </Link>
            <Link
              to="/pricing"
              className="text-lg text-white hover:text-indigo-600 font-medium transition"
              onClick={toggleMenu}
            >
              Pricing
            </Link>
            {isAuthenticated ? (
              <button
                onClick={() => {
                  handleSignOut();
                  toggleMenu();
                }}
                className="text-lg text-red-600 hover:text-red-800 font-medium transition"
              >
                Sign Out
              </button>
            ) : (
              <Link
                to="/login"
                className="text-lg text-indigo-600 hover:text-indigo-800 font-medium transition"
                onClick={toggleMenu}
              >
                Login
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MobileNavbar;
