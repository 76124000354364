import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";

interface Link {
  id: string;
  title: string;
  url: string;
}

interface PublicProfileProps {
  username?: string; // Make username optional as a prop
}

export default function PublicProfile({
  username: propUsername,
}: PublicProfileProps) {
  const { username: paramUsername } = useParams(); // Get username from URL
  const username = propUsername || paramUsername; // Use prop if provided, fallback to URL
  const [links, setLinks] = useState<Link[]>([]);
  const [backgroundColor, setBackgroundColor] = useState<string>("#ffffff");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!username) {
      setError("No username provided.");
      setLoading(false);
      return;
    }

    const fetchUserProfile = async () => {
      try {
        const q = query(
          collection(db, "users"),
          where("username", "==", username)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          setError("User not found.");
        } else {
          const userDoc = querySnapshot.docs[0].data();
          setLinks(userDoc.links || []);
          setBackgroundColor(userDoc.theme?.backgroundColor || "#ffffff"); // Use default if no color is set
        }
      } catch (error) {
        console.error("Error fetching public profile:", error);
        setError("Something went wrong. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [username]);

  if (loading) return <p className="text-center text-lg">Loading...</p>;

  if (error) return <p className="text-center text-lg text-red-500">{error}</p>;

  return (
    <div
      className="flex items-center justify-center min-h-screen px-4"
      style={{ backgroundColor }} // Dynamically set background color
    >
      <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-lg">
        <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">
          {username}
        </h2>
        <div className="grid gap-6">
          {links.map((link) => (
            <a
              key={link.id}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-between w-full px-4 py-3 bg-indigo-50 rounded-lg shadow-md hover:bg-indigo-100 transition"
            >
              <div className="flex items-center space-x-4">
                {link.title === "facebook" && (
                  <FaFacebook className="text-blue-600 text-2xl" />
                )}
                {link.title === "instagram" && (
                  <FaInstagram className="text-pink-500 text-2xl" />
                )}
                {link.title === "twitter" && (
                  <FaTwitter className="text-blue-400 text-2xl" />
                )}
                {link.title === "linkedin" && (
                  <FaLinkedin className="text-blue-700 text-2xl" />
                )}
                <span className="text-lg font-medium text-gray-800 capitalize">
                  {link.title}
                </span>
              </div>
              <span className="text-indigo-500 text-sm">↗</span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
