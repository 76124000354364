import React from "react";
import { Disclosure } from "@headlessui/react";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";

interface Link {
  id: string;
  title: string;
  url: string;
}

interface Props {
  links: Link[];
  onEdit: (link: Link) => void;
  onDelete: (linkId: string) => void;
  username: string | null;
}

export default function LinksList({
  links,
  onEdit,
  onDelete,
  username,
}: Props) {
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-[#000000] text-center">
        Your social media links
      </h2>
      <div className="grid grid-cols-1 gap-6">
        {links.map((link) => (
          <Disclosure key={link.id}>
            {({ open }) => (
              <div className="border border-[#1E2761]  overflow-hidden shadow-lg">
                <Disclosure.Button
                  className={`flex items-center justify-between w-full px-6 py-4 bg-[#080205] text-[#F4F7F7] hover-[#000000] transition `}
                >
                  <div className="flex items-center space-x-4">
                    {link.title === "facebook" && (
                      <FaFacebook className="text-blue-400 text-2xl" />
                    )}
                    {link.title === "instagram" && (
                      <FaInstagram className="text-purple-400 text-2xl" />
                    )}
                    {link.title === "twitter" && (
                      <FaTwitter className="text-blue-500 text-2xl" />
                    )}
                    {link.title === "linkedin" && (
                      <FaLinkedin className="text-blue-600 text-2xl" />
                    )}
                    <span className="text-lg font-semibold capitalize">
                      {link.title}
                    </span>
                  </div>
                  <span
                    className={`text-white text-sm transform transition ${
                      open ? "rotate-180" : ""
                    }`}
                  >
                    ▼
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="px-6 py-4 bg-gray-100">
                  <div className="flex flex-col space-y-4">
                    <p className="text-gray-800 text-base">
                      <strong>Link:</strong>{" "}
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#1E2761] underline hover:text-[#408EC6]"
                      >
                        {link.url}
                      </a>
                    </p>
                    <div className="flex space-x-4">
                      <button
                        onClick={() => onEdit(link)}
                        className="flex-grow py-2 px-4 bg-green-700 text-white font-medium hover:bg-green-600 transition shadow-md"
                      >
                        Edit Link
                      </button>
                      <button
                        onClick={() => onDelete(link.id)}
                        className="flex-grow flex items-center justify-center space-x-2 py-2 px-4 bg-[#D2385A] text-white font-medium  hover:bg-red-600 transition shadow-md"
                      >
                        <AiOutlineDelete className="text-lg" />
                        <span>Delete</span>
                      </button>
                    </div>
                  </div>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
}
