import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer";
import { FaGlobe, FaUsers, FaRocket } from "react-icons/fa";

const AboutUs = () => {
  return (
    <div className="flex flex-col min-h-screen text-gray-800 overflow-x-hidden">
      <Navbar />

      {/* First Section: Who We Are */}
      <div className="flex-1 h-screen bg-[#E17888] text-white flex flex-col justify-center items-center px-4 py-16 md:px-24 text-center relative">
        <div className="relative z-10">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 animate-fade-in">
            About Byrah
          </h1>
          <p className="text-lg md:text-2xl leading-8 max-w-3xl">
            Byrah is more than just a link-sharing platform. Born in Canada, we
            take pride in helping users connect their world in one place.
            Whether you're an influencer, a small business, or just someone
            looking to simplify their online presence, Byrah is here to make it
            easier for you.
          </p>

          <button className="mt-6 px-6 py-3 bg-white text-[#E17888] shadow-lg hover:bg-gray-100 transition">
            Learn More
          </button>
        </div>
        {/* Smooth Transition */}
        <div className="absolute bottom-0 w-full overflow-hidden leading-[0]">
          <svg
            className="relative block w-full h-[100px]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          ></svg>
        </div>
      </div>

      {/* Second Section: Why Choose Byrah */}
      <div className="flex-1 h-screen bg-[#AE3B8B] text-white flex flex-col justify-center items-center px-4 py-16 md:px-24 text-center relative">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 animate-slide-in">
          Why Choose Us?
        </h2>
        <p className="text-lg md:text-2xl leading-8 max-w-3xl mb-8">
          As a proudly Canadian company, we value inclusivity, innovation, and
          simplicity. Our mission is to give users a seamless and beautiful way
          to share their online presence. With Byrah, you can customize your
          page, track your links, and feel confident that your content is in
          safe hands.
        </p>
        {/* Features */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="flex flex-col items-center">
            <FaGlobe className="text-6xl mb-4" />
            <h3 className="text-2xl font-bold">Global Reach</h3>
            <p className="text-base leading-6 mt-2">
              Share your content across the globe with ease.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <FaUsers className="text-6xl mb-4" />
            <h3 className="text-2xl font-bold">User-Friendly</h3>
            <p className="text-base leading-6 mt-2">
              Designed with simplicity and ease of use in mind.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <FaRocket className="text-6xl mb-4" />
            <h3 className="text-2xl font-bold">Fast and Reliable</h3>
            <p className="text-base leading-6 mt-2">
              Experience lightning-fast performance and uptime.
            </p>
          </div>
        </div>
        {/* Smooth Transition */}
        <div className="absolute bottom-0 w-full overflow-hidden leading-[0]">
          <svg
            className="relative block w-full h-[100px]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          ></svg>
        </div>
      </div>

      {/* Third Section: Our Mission */}
      <div className="flex-1 h-screen bg-[#1C5789] text-white flex flex-col justify-center items-center px-4 py-16 md:px-24 text-center relative">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 animate-bounce">
          Our Mission
        </h2>
        <p className="text-lg md:text-2xl leading-8 max-w-3xl">
          At Byrah, we aim to empower creators and businesses by giving them
          tools to share their unique stories. We’re constantly evolving to
          provide better features, analytics, and designs tailored to your
          needs. Together, let’s make sharing simple and impactful.
        </p>
        <button className="mt-6 px-6 py-3 bg-white text-[#1C5789] shadow-lg hover:bg-gray-100 transition">
          Join Us
        </button>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
