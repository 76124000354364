import React, { useEffect, useState } from "react";
import { db, auth } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import LinksList from "./LinksList";
import EditLinks from "./EditLinks";
import ProfilePreview from "./ProfilePreview";

interface Link {
  id: string;
  title: string;
  url: string;
}

export default function Profile() {
  const [links, setLinks] = useState<Link[]>([]);
  const [username, setUsername] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [editLink, setEditLink] = useState<{ id: string; url: string } | null>(
    null
  );
  const [preview, setPreview] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;

      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setLinks(userData?.links || []);
            setUsername(userData?.username || "Unknown User");
          } else {
            console.error("User document does not exist.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        console.error("No authenticated user.");
      }
      setLoading(false);
    };

    fetchUserData();
  }, []);

  const saveEdit = async (updatedLink: { id: string; url: string }) => {
    const user = auth.currentUser;

    if (user) {
      try {
        const userDocRef = doc(db, "users", user.uid);
        const updatedLinks = links.map((link) =>
          link.id === updatedLink.id ? { ...link, url: updatedLink.url } : link
        );

        await updateDoc(userDocRef, { links: updatedLinks });
        setLinks(updatedLinks);
        setEditLink(null);
      } catch (error) {
        console.error("Error saving link:", error);
      }
    } else {
      console.error("No authenticated user.");
    }
  };

  const deleteLink = async (linkId: string) => {
    const user = auth.currentUser;

    if (user) {
      try {
        const userDocRef = doc(db, "users", user.uid);
        const updatedLinks = links.filter((link) => link.id !== linkId);

        await updateDoc(userDocRef, { links: updatedLinks });
        setLinks(updatedLinks);
      } catch (error) {
        console.error("Error deleting link:", error);
      }
    } else {
      console.error("No authenticated user.");
    }
  };

  if (loading) return <p className="text-center text-lg">Loading...</p>;

  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-[#5D001E] flex items-center justify-center">
        <div className="bg-white shadow-lg  p-10">
          <h1 className="text-3xl font-extrabold text-[#000000] mb-4 text-center uppercase">
            @{username}
          </h1>
          {preview ? (
            <>
              <ProfilePreview username={username || "Unknown User"} />
              <button
                onClick={() => setPreview(false)}
                className="w-full py-2 mt-4 bg-gray-600 text-white font-semibold uppercase hover:bg-gray-700 transition"
              >
                Back to Edit Mode
              </button>
            </>
          ) : (
            <>
              <LinksList
                links={links}
                onEdit={(link) => setEditLink(link)}
                onDelete={deleteLink}
                username={username}
              />
              {editLink && (
                <EditLinks
                  link={editLink}
                  onCancel={() => setEditLink(null)}
                  onSave={saveEdit}
                />
              )}
              <button
                onClick={() => navigate("/add-links")}
                className="w-full py-2 my-2 bg-[#22235f] text-[#ffffff] mt-10 font-semibold rounded-sm uppercase hover:bg-[#7a4d9f] transition"
              >
                Customize Profile
              </button>
              <button
                onClick={() =>
                  window.open(`https://byrah.com/u/${username}`, "_blank")
                }
                className="w-full py-2 bg-[#245f73] text-[#ffffff] font-semibold rounded-sm uppercase hover:bg-[#2249ae] transition"
              >
                Preview Your Profile
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
