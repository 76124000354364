import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import Auth from "./components/Auth";
import SocialLinksForm from "./components/AddLinks/SocialLinksForm";
import Profile from "./components/Profile/Profile";
import HomePage from "./components/HomePage";
import Pricing from "./components/Pricing";
import PublicProfile from "./components/PublicProfile";
import SignUp from "./components/Signup";
import Login from "./components/Login";
import AboutUs from "./components/AboutUs/AboutUs";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        {!isAuthenticated ? (
          <>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/u/:username" element={<PublicProfile />} />

            {/* Redirect authenticated users */}
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        ) : (
          <>
            {/* Private Routes */}
            <Route path="/profile" element={<Profile />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/add-links" element={<SocialLinksForm />} />
            <Route path="/u/:username" element={<PublicProfile />} />
            <Route path="/" element={<Navigate to="/profile" />} />
            {/* Redirect unauthenticated users */}
            <Route path="*" element={<Navigate to="/profile" />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
