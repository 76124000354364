import React, { useState } from "react";
import { db, auth } from "../../firebase";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import BackgroundColorSelector from "./BackgroundColorSelector";
import AddSocialLinks from "./AddSocialLinks";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert"; // Import AlertColor for type safety

export default function SocialLinksForm() {
  const [links, setLinks] = useState<{ [key: string]: string }>({});
  const [activeFields, setActiveFields] = useState<string[]>([]);
  const [backgroundColor, setBackgroundColor] = useState<string>("#ffffff");
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    type: AlertColor;
  }>({
    open: false,
    message: "",
    type: "success",
  });
  const navigate = useNavigate();

  const handleSetColor = async () => {
    const user = auth.currentUser;
    if (!user) {
      setSnackbar({
        open: true,
        message: "Please log in to save your theme.",
        type: "error",
      });
      return;
    }

    try {
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, { theme: { backgroundColor } });
      setSnackbar({
        open: true,
        message: "Background color saved!",
        type: "success",
      });
    } catch (error) {
      console.error("Error saving background color:", error);
      setSnackbar({
        open: true,
        message: "Failed to save background color.",
        type: "error",
      });
    }
  };

  const handleSaveLinks = async () => {
    const user = auth.currentUser;
    if (!user) {
      setSnackbar({
        open: true,
        message: "Please log in to save your links.",
        type: "error",
      });
      return;
    }

    try {
      const userDocRef = doc(db, "users", user.uid);

      const newLinks = Object.entries(links)
        .filter(([_, url]) => url.trim())
        .map(([platform, url]) => ({
          id: platform.toLowerCase(),
          title: platform,
          url,
        }));
      await updateDoc(userDocRef, {
        links: arrayUnion(...newLinks),
      });

      setSnackbar({
        open: true,
        message: "Links saved successfully!",
        type: "success",
      });
      setTimeout(() => navigate("/profile"), 1000);
    } catch (error) {
      console.error("Error updating Firestore:", error);
      setSnackbar({
        open: true,
        message: "Failed to save links.",
        type: "error",
      });
    }
  };

  return (
    <div>
      <Navbar />
      <div className="min-h-screen bg-[#408EC6] flex flex-col items-center justify-center px-6 py-8">
        <div className="w-full max-w-lg bg-white p-10 shadow-lg border-t-8 border-[#000000]">
          <h2 className="text-3xl font-extrabold text-center mb-4 text-[#000000]">
            Customize Your Profile
          </h2>

          {/* Background Color Selector */}
          <BackgroundColorSelector
            backgroundColor={backgroundColor}
            setBackgroundColor={setBackgroundColor}
          />
          <button
            onClick={handleSetColor}
            className="w-full py-3 mt-4 mb-6 bg-[#000000] text-white text-lg font-semibold hover:bg-[#601732] transition"
          >
            Save Background Color
          </button>

          {/* Add Social Links */}
          <AddSocialLinks
            activeFields={activeFields}
            setActiveFields={setActiveFields}
            links={links}
            setLinks={setLinks}
            handleSaveLinks={handleSaveLinks}
            setSnackbar={setSnackbar} // Add this prop
          />
        </div>
      </div>

      {/* Snackbar for alerts */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity={snackbar.type} // Now strictly typed
          sx={{
            backgroundColor:
              snackbar.type === "success" ? "#4CAF50" : "#f44336",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
