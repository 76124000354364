import React, { useState } from "react";
import { auth, db } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, serverTimestamp, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate fields
    if (!username.trim()) {
      toast.error("Username is required!");
      return;
    }
    if (!email.trim()) {
      toast.error("Email is required!");
      return;
    }
    if (!password.trim()) {
      toast.error("Password is required!");
      return;
    }

    try {
      // Check if username already exists
      const usernameDoc = await getDoc(doc(db, "profiles", username));
      if (usernameDoc.exists()) {
        toast.error("Username already exists. Please choose another.");
        return;
      }

      // Create the user
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Save user data to Firestore
      await setDoc(doc(db, "users", user.uid), {
        email,
        username,
        createdAt: serverTimestamp(),
      });

      // Save username lookup to Firestore
      await setDoc(doc(db, "profiles", username), {
        userId: user.uid,
      });

      toast.success("Account created successfully!");
      navigate("/profile");
    } catch (error: any) {
      console.error("Error signing up:", error);

      // Handle Firebase errors
      switch (error.code) {
        case "auth/email-already-in-use":
          toast.error("This email is already in use.");
          break;
        case "auth/invalid-email":
          toast.error("Invalid email address.");
          break;
        case "auth/weak-password":
          toast.error("Password should be at least 6 characters.");
          break;
        case "auth/operation-not-allowed":
          toast.error("Sign up is currently disabled. Please try again later.");
          break;
        default:
          toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <div
      className="flex flex-col min-h-screen"
      style={{
        backgroundColor: "#2e9cca",
      }}
    >
      {/* Navbar */}
      <Navbar />

      {/* Toast Container */}
      <ToastContainer position="top-right" autoClose={3000} />

      {/* Sign Up Form */}
      <div className="flex items-center justify-center flex-grow px-4">
        <div className="bg-white p-10 shadow-2xl max-w-lg w-full md:w-3/4 lg:w-1/2 border-t-8 border-[#000000]">
          <h2 className="text-4xl font-extrabold text-center mb-6 text-[#000000]">
            Create Your Account
          </h2>
          <p className="text-center text-[#000000] mb-6">
            Join us today and manage your online presence effortlessly!
          </p>
          <form onSubmit={handleSignUp} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-[#000000] mb-1">
                Username{" "}
                <span className="text-red-500" title="Required">
                  *
                </span>
              </label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                className="w-full px-4 py-3 text-lg border border-gray-300 focus:outline-none focus:ring-4 focus:ring-[#3B5BA5] transition shadow-sm"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-[#000000] mb-1">
                Email{" "}
                <span className="text-red-500" title="Required">
                  *
                </span>
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="w-full px-4 py-3 text-lg border border-gray-300 focus:outline-none focus:ring-4 focus:ring-[#3B5BA5] transition shadow-sm"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-[#000000] mb-1">
                Password{" "}
                <span className="text-red-500" title="Required">
                  *
                </span>
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="w-full px-4 py-3 text-lg border border-gray-300 focus:outline-none focus:ring-4 focus:ring-[#3B5BA5] transition shadow-sm"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full py-3 bg-[#000000] text-white text-lg font-bold hover:bg-[#552619] transition duration-300 transform hover:scale-105 shadow-lg"
            >
              Sign Up
            </button>
          </form>
          <p className="text-center text-[#000000] mt-6">
            Already have an account?{" "}
            <span
              className="text-[#C50900] underline cursor-pointer hover:text-[#552619]"
              onClick={() => navigate("/login")}
            >
              Log in
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
