import React from "react";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

// Define props for DesktopNavbar
type NavbarProps = {
  isAuthenticated: boolean;
  handleSignOut: () => void;
};

const DesktopNavbar: React.FC<NavbarProps> = ({
  isAuthenticated,
  handleSignOut,
}) => {
  return (
    <div className="hidden md:flex items-center space-x-6">
      {/* Navigation Links */}
      <Link
        to="/home"
        className="text-lg font-medium text-white hover:text-indigo-600 transition duration-300 relative group"
      >
        Home
        <span className="absolute bottom-[-2px] left-0 w-0 h-[2px] bg-indigo-600 transition-all duration-300 group-hover:w-full"></span>
      </Link>
      <Link
        to="/about"
        className="text-lg font-medium text-white hover:text-indigo-600 transition duration-300 relative group"
      >
        About
        <span className="absolute bottom-[-2px] left-0 w-0 h-[2px] bg-indigo-600 transition-all duration-300 group-hover:w-full"></span>
      </Link>

      <Link
        to="/pricing"
        className="text-lg font-medium text-white hover:text-indigo-600 transition duration-300 relative group"
      >
        Pricing
        <span className="absolute bottom-[-2px] left-0 w-0 h-[2px] bg-indigo-600 transition-all duration-300 group-hover:w-full"></span>
      </Link>

      {/* Authentication Button */}
      {isAuthenticated ? (
        <button
          onClick={handleSignOut}
          className="ml-4 px-6 py-2 text-white font-semibold shadow-lg bg-byrah-primary from-red-500 via-red-600 to-red-700 hover:from-red-600 hover:via-red-700 hover:to-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 transform hover:scale-105 transition-all duration-300"
        >
          Sign Out
        </button>
      ) : (
        <Link
          to="/login"
          className="ml-4 px-6 py-2 text-black font-medium  shadow-md bg-white transition-all duration-300"
        >
          Sign in
        </Link>
      )}

      {/* Profile Logo */}
      {isAuthenticated && (
        <Link to="/profile" className="ml-auto">
          <AccountCircle
            sx={{
              fontSize: 50, // Size of the icon
              cursor: "pointer",
              color: "#7A2048", // Default color
              "&:hover": {
                color: "#5A00CC", // Hover color
                transform: "scale(1.1)",
                transition: "all 0.3s ease",
              },
            }}
          />
        </Link>
      )}
    </div>
  );
};

export default DesktopNavbar;
