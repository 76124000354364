import React from "react";

interface Props {
  link: { id: string; url: string };
  onSave: (updatedLink: { id: string; url: string }) => void;
  onCancel: () => void;
}

export default function EditLinks({ link, onSave, onCancel }: Props) {
  const [url, setUrl] = React.useState(link.url);

  const handleSave = () => {
    onSave({ id: link.id, url });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white  shadow-lg w-96 p-6">
        <h3 className="text-xl font-semibold text-gray-800 mb-4">Edit Link</h3>
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          className="w-full p-2 border focus:outline-none focus:ring-2 focus:ring-indigo-500 mb-4"
          placeholder="Enter new URL"
        />
        <div className="flex justify-end space-x-4">
          <button
            onClick={handleSave}
            className="py-2 px-4 bg-green-700 text-white  hover:bg-green-900 transition"
          >
            Save
          </button>
          <button
            onClick={onCancel}
            className="py-2 px-4 bg-black text-white hover:bg-gray-600 transition"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
