import React from "react";
import PublicProfile from "../PublicProfile";

interface Props {
  username: string;
}

export default function ProfilePreview({ username }: Props) {
  return (
    <div className="w-full max-w-lg bg-white p-8 rounded-lg shadow-lg">
      <PublicProfile username={username} />
    </div>
  );
}
