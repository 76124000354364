import React from "react";
import {
  FaInstagram,
  FaTwitter,
  FaFacebook,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="bg-black text-gray-200 py-16 px-10">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Column 1: Title and Description */}
        <div className="flex flex-col items-start">
          <h2 className="text-3xl font-bold mb-4">Get in Touch</h2>
          <p className="text-lg">
            Have questions or feedback? We’d love to hear from you. Reach us via
            email or social media.
          </p>
        </div>

        {/* Column 2: Social Media Links */}
        <div className="flex flex-col items-start">
          <h3 className="text-2xl font-semibold mb-4">Follow Us</h3>
          <div className="flex flex-col space-y-4">
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 hover:underline"
            >
              <FaInstagram className="text-2xl text-pink-400" />
              <span>Instagram</span>
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 hover:underline"
            >
              <FaTwitter className="text-2xl text-blue-300" />
              <span>Twitter</span>
            </a>
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 hover:underline"
            >
              <FaFacebook className="text-2xl text-blue-500" />
              <span>Facebook</span>
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 hover:underline"
            >
              <FaLinkedin className="text-2xl text-blue-400" />
              <span>LinkedIn</span>
            </a>
            <a
              href="https://youtube.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 hover:underline"
            >
              <FaYoutube className="text-2xl text-red-400" />
              <span>YouTube</span>
            </a>
          </div>
        </div>

        {/* Column 3: Contact Button */}
        <div className="flex flex-col items-start md:items-end">
          <h3 className="text-2xl font-semibold mb-4">Contact Us</h3>
          <a
            href="mailto:byrah.app@gmail.com"
            className="px-6 py-3 bg-gray-200 text-green-900 shadow-lg hover:bg-gray-300 transition"
          >
            Get in Touch
          </a>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="border-t border-gray-600 mt-8 pt-4 text-center text-sm text-gray-400">
        © {new Date().getFullYear()} Byrah. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
