import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";

const Navbar = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe();
  }, []);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <nav className="bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16 border-b ">
          {/* Logo */}
          <div>
            <Link
              to="/"
              className="text-4xl text-white  transition transform hover:scale-110"
            >
              Byrah
            </Link>
          </div>

          {/* Desktop Navbar */}
          <DesktopNavbar
            isAuthenticated={isAuthenticated}
            handleSignOut={handleSignOut}
          />

          {/* Mobile Navbar */}
          <MobileNavbar
            isAuthenticated={isAuthenticated}
            toggleMenu={toggleMenu}
            handleSignOut={handleSignOut}
            isOpen={isOpen}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
