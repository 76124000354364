import React, { useState, useEffect } from "react";
import {
  FaInstagram,
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
  FaWhatsapp,
  FaSnapchat,
  FaTiktok,
} from "react-icons/fa";

const SlidingLinks = () => {
  const links = [
    {
      icon: <FaInstagram className="text-3xl md:text-4xl mr-4" />,
      name: "Instagram",
      bg: "bg-pink-600",
    },
    {
      icon: <FaFacebook className="text-3xl md:text-4xl mr-4" />,
      name: "Facebook",
      bg: "bg-blue-600",
    },
    {
      icon: <FaTwitter className="text-3xl md:text-4xl mr-4" />,
      name: "Twitter",
      bg: "bg-blue-500",
    },
    {
      icon: <FaLinkedin className="text-3xl md:text-4xl mr-4" />,
      name: "LinkedIn",
      bg: "bg-blue-800",
    },
    {
      icon: <FaYoutube className="text-3xl md:text-4xl mr-4" />,
      name: "YouTube",
      bg: "bg-red-600",
    },
    {
      icon: <FaWhatsapp className="text-3xl md:text-4xl mr-4" />,
      name: "WhatsApp",
      bg: "bg-green-600",
    },
    {
      icon: <FaSnapchat className="text-3xl md:text-4xl mr-4" />,
      name: "Snapchat",
      bg: "bg-yellow-600",
    },
    {
      icon: <FaTiktok className="text-3xl md:text-4xl mr-4" />,
      name: "TikTok",
      bg: "bg-black",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 2); // Cycle between 2 groups
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const visibleLinks = links.slice(currentIndex * 4, currentIndex * 4 + 4);

  return (
    <div className="relative w-full overflow-hidden">
      <div className="flex flex-wrap justify-center gap-6">
        {visibleLinks.map((link, i) => (
          <div
            key={i}
            className={`flex items-center ${link.bg} text-white p-4 shadow-xl w-full max-w-xs transform transition-transform hover:scale-105`}
          >
            {link.icon}
            <span className="text-lg md:text-xl font-medium">{link.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SlidingLinks;
